<template>
  <section>
    <Card :loading="loading.get">
      <template #header>
        <StepCardHeader
          v-model="current"
          :steps="steps"
          :onlyFirstAllowed="oEmployee.id ? false : true"
          returnRoute="/data/employees"
        />
      </template>
      <General-step v-if="current == 1" :employee="oEmployee" @change="onChange" />
      <Home-address v-if="current == 2" :employee="oEmployee" />
    </Card>
  </section>
</template>

<script>
import Card from '@/components/Card.vue';
import GeneralStep from './Steps/General/General.vue';
import HomeAddress from './Steps/HomeAddress.vue';
import StepCardHeader from '@/components/StepCardHeader';
import { Permissions } from '@/utils/Secure';
const { Employees } = Permissions;
export default {
  components: {
    Card,
    GeneralStep,
    HomeAddress,
    StepCardHeader
  },
  async created() {
    if (this.paramsEmployeeId) await this.getEmployee();
    if (!this.oEmployee.first_name) {
      this.$router.push('/data/employees/add');
    }
  },
  data() {
    const paramsEmployeeId = Number(this.$route.params.employeeId || 0);
    return {
      paramsEmployeeId,
      loading: { get: false, save: false },
      oEmployee: { id: paramsEmployeeId },
      steps: [
        { name: 'global.general', permission: Employees.update },
        { name: 'global.homeAddress', permission: Employees.update }
      ],
      current: this.$route.meta?.current || 1
    };
  },
  computed: {},
  methods: {
    async getEmployee() {
      this.loading.get = true;
      try {
        const { data } = await this.Api.get(`employees/${this.paramsEmployeeId}`);
        this.oEmployee = data;
      } catch (error) {
        console.error(error);
      }
      this.loading.get = false;
    },
    onChangeStep(step = 1) {
      const routes = ['', '/homeAddress'];
      if (this.oEmployee.id) {
        this.$router.replace({
          path: `/data/employees/${this.oEmployee.id}${routes[step - 1]}`
        });

        this.current = step;
      }
    },
    onChange(employee) {
      this.oEmployee = employee;
      this.paramsEmployeeId = employee.id;
    },
    goBack() {
      // TODO validate history to stay
      this.$router.go(-1);
    }
  }
};
</script>
<style lang="sass" scoped>
.card
  margin: 0
  min-height: calc(100% - 90px)
  ::v-deep
    .card-header
      padding: 0
      justify-content: flex-end
</style>
