<template>
  <section class="homeAddress">
    <div class="columns">
      <div class="column">
        <div class="l-title">
          {{ $t('homeAddress.title') }}
        </div>
        <AddressPicker ref="addressPicker" v-model="address" />
        <save-button @click="onSave" :loading="loading.save" :disabled="!address" />
      </div>
    </div>
  </section>
</template>

<script>
import AddressPicker from '@/components/Address/AddressPicker.vue';
import saveButton from './saveButton.vue';
import { toast } from '@/utils/dialog';
export default {
  components: {
    AddressPicker,
    saveButton
  },
  mounted() {
    this.$store.dispatch('setBreadcumbs', this.breadcumbs);
  },
  created() {
    this.unsubscribe = this.$store.subscribe(({ type }, { map }) => {
      if (type == 'map/response') {
        console.warn(map?.response?.data?.latLng);
        if (map?.response?.action == 'position') this.setPosition(map?.response?.data?.latLng);
      }
    });
  },
  destroyed() {
    this.unsubscribe();
  },
  computed: {
    breadcumbs() {
      return [
        'menu.dat',
        this.employee.id ? 'employee.edit' : 'employee.new',
        'global.homeAddress'
      ];
    }
  },
  data() {
    return {
      loading: { save: false, get: false },
      address: {
        address: this.employee.address,
        address_1: this.employee.address_1,
        city: this.employee.city,
        state: this.employee.state,
        zipcode: this.employee.zipcode,
        latitude: this.employee.latitude,
        longitude: this.employee.longitude
      },
      unsubscribe: null
    };
  },
  methods: {
    async onSave() {
      this.loading.save = true;
      if (this.validate()) {
        await this.updateAddress();
      }
      this.loading.save = false;
    },
    async updateAddress() {
      const { employee, address } = this;
      try {
        await this.Api.put(`/employees/${employee.id}`, {
          address: address.address,
          address_1: address.address_1,
          state: address.state,
          city: address.city,
          zipcode: address.zipcode,
          latitude: address.latitude,
          longitude: address.longitude
        });
        toast('success', this.$t('messages.updated'), 5000);
      } catch (error) {
        console.error(error);
      }
    },
    onPickPosition(address) {
      this.showMap(address, true);
    },
    onShowPosition(address) {
      this.showMap(address, false);
    },
    prepareForMaps({ address, state, city, latitude, longitude, zipcode }) {
      const comp = [];
      if (address) comp.push(address);
      if (city) comp.push(city);
      if (state) comp.push(state);
      if (zipcode) comp.push(`zc ${zipcode}`);
      return {
        address: comp.join(', '),
        latLng: { lat: latitude, lng: longitude }
      };
    },
    restoreMap() {
      this.$store.commit('map/update', { action: 'restore' });
    },
    setPosition({ lat, lng } = {}) {
      this.address.latitude = lat || null;
      this.address.longitude = lng || null;
    },
    showMap(oAdress, pick) {
      const data = this.prepareForMaps(oAdress);
      this.$store.dispatch('map/updateMapWindow', {
        action: pick ? 'getPosition' : 'showPosition',
        data
      });
    },
    updateMapBreadcumbs(clear = false) {
      this.$store.commit('map/breadcumbs', clear ? null : this.breadcumbs);
    },
    validate() {
      let htmlValidator = true;
      if (this.$refs.addressPicker) htmlValidator = this.$refs.addressPicker.validate();
      return htmlValidator;
    }
  },
  watch: {
    employee(E) {
      this.address = {
        address: E.address,
        address_1: E.address_1,
        city: E.city,
        state: E.state,
        zipcode: E.zipcode,
        latitude: E.latitude,
        longitude: E.longitude
      };
    }
  },
  props: {
    employee: {
      type: Object,
      required: true,
      default: () => {}
    }
  }
};
</script>

<style lang="sass" scoped>
.homeAddress
  .columns
    justify-content: space-between
  .l-title
    font-size: $f-xl
    font-weight: bold
    margin-bottom: 20px
  .column
    min-width: 100%
    max-width: 100%
    .p-label
      margin: 15px 0
    .address-picker
      margin: 50px 0
      ::v-deep
        .ap-address,
        .ap-address1
          width: calc(50% - .39rem)
        .ap-address1
          margin-right: 0px
        .ap-city,
        .ap-zipcode,
        .ap-state
          width: calc(25% - .75rem)
</style>
