<template>
  <div class="saveButton">
    <b-button
      type="is-primary"
      :label="$t('button.save')"
      @click="() => this.$emit('click')"
      v-bind="{ ...$props, ...$attrs }"
    />
  </div>
</template>

<script>
export default {};
</script>
<style lang="sass" scoped>
.saveButton
  display: flex
  justify-content: flex-end
  width: 100%
</style>
